<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Venue</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="venueInsert" enctype="multipart/form-data">
              <div class="card-body">
                <text-input
                  :value.sync="form.title"
                  label="Title"
                  :error="errors.title"
                ></text-input>
                <textarea-input
                  :value.sync="form.address"
                  label="Address"
                ></textarea-input>
                <div class="form-group">
                  <label>Type</label>
                  <select class="form-control" v-model="form.venue_type_id">
                    <option value="">Select Venue Type</option>
                    <option
                      :value="venue_type.id"
                      v-for="(venue_type, index) in venue_types"
                      :key="index"
                    >
                      {{ venue_type.name }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="errors.venue_type_id">{{
                    errors.venue_type_id[0]
                  }}</small>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Open At</label>
                    <input
                      type="time"
                      class="form-control"
                      v-model="form.open_at"
                    />
                    <small class="text-danger" v-if="errors.open_at">{{
                      errors.open_at[0]
                    }}</small>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Closed At</label>
                    <input
                      type="time"
                      class="form-control"
                      v-model="form.closed_at"
                    />
                    <small class="text-danger" v-if="errors.closed_at">{{
                      errors.closed_at[0]
                    }}</small>
                  </div>
                </div>
                <div class="form-group">
                  <label>Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    class="form-control"
                    @change="onImageSelected"
                  />
                  <img :src="form.image" alt="" style="height:0px" />
                  <small class="text-danger" v-if="errors.image">{{
                    errors.image[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { apiGet } from "../../services/api";

export default {
  name: "FormVenue",
  data() {
    return {
      name: "Form Venue",
      form: {
        title: "",
        address: "",
        open_at: "",
        closed_at: "",
        venue_type_id: "",
        image: null,
      },
      venue_types: [],
      errors: {},
      isSubmitting: false,
    };
  },
  mounted() {
    this.getType();
  },
  methods: {
    onImageSelected(event) {
      this.form.image = event.target.files[0];
    },
    getType() {
      apiGet("venue/get_venue_type")
        .then((result) => {
          this.venue_types = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    venueInsert() {
      this.isSubmitting = true;
      let formData = new FormData();
      formData.set("title", this.form.title);
      formData.set("open_at", this.form.open_at);
      formData.set("address", this.form.address);
      formData.set("closed_at", this.form.closed_at);
      formData.set("venue_type_id", this.form.venue_type_id);
      formData.set("image", this.form.image);

      axios
        .post(this.$store.state.api + "venue/store", formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          if (res.data.code == 500) {
            swal("Error!", res.data.message, "error");
          } else {
            this.$noty.success("Your venue has been saved!");
            this.$router.push({ name: "Venue" });
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
